const handleResp = async (resp) => {
    if (resp.status && resp.status === 204) {
        return null;
    } else if (resp.status && (resp.status < 200 || resp.status > 302)) {
        let json;
        try {
            json = await resp.json();
        } catch (err) {
            if (resp.status === 404) {
                throw {
                    code: "NOT_FOUND",
                    message: resp.statusText
                };
            } else if (resp.status === 413) {
                throw {
                    code: "CONTENT_TOO_LARGE",
                    message: resp.statusText
                };
            }
            throw {
                code: "ERROR",
                message: resp.statusText
            };
        }
        throw json.error || {};
    }
    return await resp.json();
};

const handleBlob = async (resp) => {
    if (resp.status && (resp.status < 200 || resp.status > 302)) {
        let json;
        try {
            json = await resp.json();
        } catch (err) {
            throw resp.statusText;
        }
        throw json.error || {};
    }
    return await resp.blob();
};

const getJson = async (url, enableCORS, queryParams = []) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    queryParams.push("t=" + new Date().getTime());
    const resp = await fetch(
        url + "?" + queryParams.join("&"),
        Object.assign({}, options, {
            method: "GET",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json"
            }
        })
    );
    return await handleResp(resp);
};

const getAuthJson = async (url, enableCORS, accessToken, queryParams = []) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    queryParams.push("t=" + new Date().getTime());
    const resp = await fetch(
        url + "?" + queryParams.join("&"),
        Object.assign({}, options, {
            method: "GET",
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            }
        })
    );
    return await handleResp(resp);
};

const getAuthBlob = async (url, enableCORS, accessToken, queryParams = []) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    queryParams.push("t=" + new Date().getTime());
    const resp = await fetch(
        url + "?" + queryParams.join("&"),
        Object.assign({}, options, {
            method: "GET",
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            }
        })
    );
    return await handleBlob(resp);
};

const postAuthJson = async (url, data, enableCORS, accessToken) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    const resp = await fetch(
        url,
        Object.assign({}, options, {
            method: "POST",
            body: JSON.stringify(data),
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            }
        })
    );
    return await handleResp(resp);
};

const postAuthFormData = async (url, data, enableCORS, accessToken) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    const resp = await fetch(
        url,
        Object.assign({}, options, {
            method: "POST",
            body: data,
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
    );
    return await handleResp(resp);
};

const putAuth = async (url, enableCORS, accessToken) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    const resp = await fetch(
        url,
        Object.assign({}, options, {
            method: "PUT",
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
    );
    return await handleResp(resp);
};

const putAuthJson = async (url, data, enableCORS, accessToken) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    const resp = await fetch(
        url,
        Object.assign({}, options, {
            method: "PUT",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            }
        })
    );
    return await handleResp(resp);
};

const deleteAuth = async (url, enableCORS, accessToken) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }
    const resp = await fetch(
        url,
        Object.assign({}, options, {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
    );
    return await handleResp(resp);
};

export {
    getJson,
    getAuthJson,
    getAuthBlob,
    postAuthJson,
    postAuthFormData,
    putAuth,
    putAuthJson,
    deleteAuth
};

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { CurrentUserProvider } from "../providers/auth";

import SEO from "./seo";
import AuthorizedContent from "./authorizedContent";
import Header from "./header";

const Layout = ({
    checkAuth,
    children,
    link,
    seoTitle,
    mainDivCss,
    permissionValidator,
    refreshSessionCallback,
    forceCheckSessionCount
}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <CurrentUserProvider>
            <SEO title={seoTitle} />
            <Header siteTitle={data.site.siteMetadata.title} link={link} />
            <div className={mainDivCss}>
                <main>
                    {checkAuth ? (
                        <AuthorizedContent
                            permissionValidator={permissionValidator}
                            refreshSessionCallback={refreshSessionCallback}
                            forceCheckSessionCount={forceCheckSessionCount}
                        >
                            {children}
                        </AuthorizedContent>
                    ) : (
                        children
                    )}
                </main>
            </div>
        </CurrentUserProvider>
    );
};

Layout.propTypes = {
    checkAuth: PropTypes.bool,
    children: PropTypes.node.isRequired,
    link: PropTypes.string,
    seoTitle: PropTypes.string.isRequired,
    mainDivCss: PropTypes.string,
    permissionValidator: PropTypes.func,
    refreshSessionCallback: PropTypes.func,
    forceCheckSessionCount: PropTypes.number
};

Layout.defaultProps = {
    checkAuth: true,
    link: "",
    mainDivCss: "px-4 pt-3 lg:px-8 mb-12"
};

export default Layout;

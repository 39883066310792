const DEF_TITLE =
    "Are you sure to leave the page? Edited records are not saved yet.";
export const KEY_DATA_VALIDATE_EDITING = "data-validate-editing";
const LOCKED_LAMPPOST_ID = "locked-lamppost-id";

const isValidatePage = () =>
    window.location.pathname === "/validation/validate/" ||
    window.location.pathname === "/validation/validate";

export const setLockedLamppost = (lamppostId) => {
    if (!lamppostId) {
        window.localStorage.removeItem(LOCKED_LAMPPOST_ID);
        return;
    }
    window.localStorage.setItem(LOCKED_LAMPPOST_ID, lamppostId);
};

export const getLockedLamppost = () =>
    window.localStorage.getItem(LOCKED_LAMPPOST_ID);

export const unlockAndAction = (lamppostService, handleErrFunc, actionFunc) => {
    window.localStorage.removeItem(KEY_DATA_VALIDATE_EDITING);
    const lamppostId = getLockedLamppost();
    if (lamppostId) {
        lamppostService
            .unlockLamppostMetrics(lamppostId)
            .then(() => {
                setLockedLamppost(null);
                actionFunc();
            })
            .catch((err) => {
                handleErrFunc(err);
                console.error("Cannot unlock:", err);
            });
    } else {
        actionFunc();
    }
};

const confirmClose = (title = DEF_TITLE) => {
    const r = window.confirm(title);
    if (r === true) {
        return true;
    }
    return false;
};

export const confirmCloseWindow = (flag, title = DEF_TITLE) => {
    if (!flag) {
        return;
    }
    return confirmClose(title);
};

export const isEditingValidation = () =>
    isValidatePage() &&
    window.localStorage.getItem(KEY_DATA_VALIDATE_EDITING) === "true";

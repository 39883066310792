import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Loading from "./loading";

const ConfirmBox = ({
    action,
    cancelAction,
    labelOk = "Yes",
    labelCancel = "Cancel",
    deleteBtn = false,
    savingTitle = "Processing",
    children
}) => {
    const [saving, setSaving] = useState(false);
    const btnDelete = React.createRef();

    useEffect(() => {
        if (deleteBtn) {
            btnDelete.current.focus();
        }
    });

    return (
        <div className="fixed inset-0 z-50 w-full h-screen flex flex-row items-center justify-around text-sm">
            <div className="mx-8 lg:w-1/2 p-4 border bg-white z-50 shadow-lg max-h-screen overflow-y-auto">
                {children}
                <div className={"mt-4 text-center" + (saving ? " hidden" : "")}>
                    <button
                        className={deleteBtn ? "btn-delete" : "btn-primary"}
                        onClick={() => {
                            setSaving(true);
                            action();
                        }}
                    >
                        {labelOk}
                    </button>
                    <button
                        className="ml-6 btn-secondary"
                        onClick={cancelAction}
                        ref={btnDelete}
                    >
                        {labelCancel}
                    </button>
                </div>
                {saving && (
                    <div className="mt-4 text-center">
                        <Loading cssClass="" word={savingTitle} />
                    </div>
                )}
            </div>
            <div className="fixed top-0 left-0 w-full h-full opacity-25 bg-gray-500 z-10"></div>
        </div>
    );
};

export default ConfirmBox;

ConfirmBox.propTypes = {
    action: PropTypes.func.isRequired,
    cancelAction: PropTypes.func.isRequired,
    deleteBtn: PropTypes.bool,
    labelOk: PropTypes.string,
    labelCancel: PropTypes.string,
    savingTitle: PropTypes.string,
    children: PropTypes.node.isRequired
};

import { getAuthJson } from "../utils/fetch";

export const LogService = (contextUser, apiURL, enableCORS) => {
    const logsURL = `${apiURL}/logs`;
    return {
        getLogs: ({ username, from, to, actionType, page }) => {
            let queries = []
                .concat(username ? ["username=" + username] : [])
                .concat(from ? ["from=" + from] : [])
                .concat(to ? ["to=" + to] : [])
                .concat(actionType ? ["action_type=" + actionType] : [])
                .concat(page ? ["page=" + page] : []);
            return contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(logsURL, enableCORS, accessToken, queries)
                );
        },
        getDataValidationLogs: ({ validatedBy, validatedAt }) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${logsURL}/data-validations`,
                        enableCORS,
                        accessToken,
                        [
                            "validated_by=" + validatedBy,
                            "validated_at=" + validatedAt
                        ]
                    )
                )
    };
};

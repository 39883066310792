import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Loading from "./loading";

import { useSettings } from "../hooks";
import { CurrentUserContext } from "../providers/auth";
import { removeTrailingSlash } from "../utils/links";
import {
    setCurrentLocationAsRedirectUrlWhenNotSet,
    setLastTimeoutLogoutUser,
    getRedirectUrl,
    getLastTimeoutLogoutUser
} from "../utils/session";

const AuthorizedContent = ({
    children,
    permissionValidator,
    refreshSessionCallback,
    forceCheckSessionCount
}) => {
    const { SESSION_REFRESH_INTERVAL_MINUTES } = useSettings();
    const currentUser = useContext(CurrentUserContext);

    const [loading, setLoading] = useState(true);
    const [hasSession, setHasSession] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);

    let handleIdleTimer = null;

    const handleActiveSession = (user) => {
        setHasSession(true);
        if (permissionValidator) {
            setHasPermission(!!permissionValidator(user.profile));
        } else {
            setHasPermission(true);
        }
    };

    const handleNoActiveSession = (user) => {
        if (currentUser) {
            setCurrentLocationAsRedirectUrlWhenNotSet();

            if (user) {
                setLastTimeoutLogoutUser(user.profile.sub);

                // signout expired user session
                currentUser.signOut();
            }
            setHasSession(false);
        }
    };

    const checkActiveSession = () => {
        if (currentUser) {
            currentUser.getSessionUser().then((user) => {
                if (user && !user.expired) {
                    // refresh user session if not idle
                    if (!currentUser.isIdle()) {
                        currentUser.refreshUserSession().then((user) => {
                            if (user) {
                                handleActiveSession(user);
                                if (refreshSessionCallback) {
                                    refreshSessionCallback();
                                }
                            }
                        });
                    }
                } else {
                    handleNoActiveSession(user);
                }
            });
        }
    };

    useEffect(() => {
        if (currentUser) {
            currentUser.getSessionUser().then((user) => {
                if (user && !user.expired) {
                    handleActiveSession(user);

                    // check whether user is idle periodically
                    handleIdleTimer = window.setInterval(() => {
                        checkActiveSession();
                    }, SESSION_REFRESH_INTERVAL_MINUTES * 60 * 1000);
                } else {
                    handleNoActiveSession(user);

                    const timeoutUser = getLastTimeoutLogoutUser();
                    const redirectUrl = getRedirectUrl();
                    if (
                        redirectUrl &&
                        timeoutUser &&
                        removeTrailingSlash(window.location.href) !==
                            removeTrailingSlash(redirectUrl)
                    ) {
                        window.location.href = redirectUrl;
                    }
                }
                setLoading(false);
            });
        }
        return () => handleIdleTimer && window.clearInterval(handleIdleTimer);
    }, [currentUser]);

    useEffect(() => {
        if (forceCheckSessionCount > 0) {
            checkActiveSession();
        }
    }, [forceCheckSessionCount]);

    return loading ? (
        <Loading />
    ) : hasSession ? (
        hasPermission ? (
            children
        ) : (
            <NoPermission />
        )
    ) : (
        <LoginToProcced />
    );
};

AuthorizedContent.propTypes = {
    children: PropTypes.node.isRequired,
    permissionValidator: PropTypes.func,
    refreshSessionCallback: PropTypes.func,
    forceCheckSessionCount: PropTypes.number.isRequired
};

AuthorizedContent.defaultProps = {
    forceCheckSessionCount: 0
};

export default AuthorizedContent;

const LoginToProcced = () => {
    const currentUser = useContext(CurrentUserContext);
    return (
        <div className="text-center py-32">
            <div className="title-h2">Please login to access this page.</div>
            <button
                autoFocus
                className="btn-primary m-4"
                onClick={() => {
                    if (currentUser) {
                        setCurrentLocationAsRedirectUrlWhenNotSet();
                        currentUser.clearSessionAndSignIn();
                    }
                }}
            >
                <span className="px-4 inline-block">Go To Login</span>
            </button>
        </div>
    );
};

const NoPermission = () => (
    <div className="text-center py-32">
        <span className="title-h2 red">
            You have no privilege to access this page.
        </span>
    </div>
);

import {
    getAuthJson,
    postAuthJson,
    deleteAuth,
    putAuthJson
} from "../utils/fetch";

export const UserService = (contextUser, apiURL, enableCORS) => {
    const usersURL = `${apiURL}/users`;
    return {
        getUsers: () =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(usersURL, enableCORS, accessToken)
                ),
        addUser: (user) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    postAuthJson(usersURL, user, enableCORS, accessToken)
                ),
        updateUser: (user) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    putAuthJson(
                        `${usersURL}/${user.username}`,
                        user,
                        enableCORS,
                        accessToken
                    )
                ),
        updateAccountPassword: ({ password, newPassword }) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    putAuthJson(
                        `${apiURL}/account/password`,
                        { password, new_password: newPassword },
                        enableCORS,
                        accessToken
                    )
                ),
        deleteUser: (username) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    deleteAuth(
                        `${usersURL}/${username}`,
                        enableCORS,
                        accessToken
                    )
                )
    };
};

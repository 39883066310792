import { useSettings } from "../hooks";
import { LamppostService } from "./lamppost";
import { ImportDataService } from "./imported";
import { UserService } from "./user";
import { SystemService } from "./system";
import { LogService } from "./log";

export default (contextUser) => {
    const { ADMIN_API_URL, ADMIN_API_CORS } = useSettings();
    return {
        user: UserService(contextUser, ADMIN_API_URL, ADMIN_API_CORS),
        lamppost: LamppostService(contextUser, ADMIN_API_URL, ADMIN_API_CORS),
        importData: ImportDataService(
            contextUser,
            ADMIN_API_URL,
            ADMIN_API_CORS
        ),
        system: SystemService(contextUser, ADMIN_API_URL, ADMIN_API_CORS),
        log: LogService(contextUser, ADMIN_API_URL, ADMIN_API_CORS)
    };
};

export const isUnauthorizedError = (err) => err && err.code === "UNAUTHORIZED";

export const isForbiddenError = (err) => err && err.code === "FORBIDDEN";

export const hasAuthError = (err) =>
    isUnauthorizedError(err) || isForbiddenError(err);

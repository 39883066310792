import { useStaticQuery, graphql } from "gatsby";

export const useSettings = () => {
    return useStaticQuery(graphql`
        query SettingsQuery {
            site {
                siteMetadata {
                    ADMIN_API_URL
                    ADMIN_API_CORS
                    ADMIN_AUTH_URL
                    ADMIN_WEBSITE_URL
                    OAUTH2_CLIENT_ID
                    OAUTH2_AUTH_URL
                    SESSION_REFRESH_INTERVAL_MINUTES
                    GRF_SAME_ORIGIN
                    grfLogoutUrl
                    grfPanels {
                        movingAvg1Min
                        movingAvg5Min
                        movingAvg10Min
                        movingAvg1Hour
                        movingAvg1Day
                        movingAvg1Week
                        movingAvg1Month
                    }
                    grfDashboard {
                        raw {
                            byLamppost
                            byPollutant
                            byLamppostAvg
                            byPollutantAvg
                            sensor
                            sensorParams
                        }
                        interim {
                            byLamppost
                            byPollutant
                            byLamppostAvg
                            byPollutantAvg
                            compare
                            compareAvg
                        }
                        approved {
                            byLamppost
                            byPollutant
                            byLamppostAvg
                            byPollutantAvg
                            compare
                            compareAvg
                        }
                        imported {
                            compare
                        }
                    }
                    MAP_API_KEY
                }
            }
        }
    `).site.siteMetadata;
};

import UserUtil from "./user";

const Links = {
    Home: "/",
    Latest: "/latest",
    DataAnalysis: "/analysis/raw",
    Report: "/report",
    DaRaw: "/analysis/raw",
    DaInterim: "/analysis/interim",
    DaApproved: "/analysis/approved",
    DaImported: "/analysis/imported",
    Validation: "/validation",
    Logs: "/logs",
    // Admin: System admin menu, link to user management by default.
    Admin: "/admin/users",
    Users: "/admin/users",
    Lampposts: "/admin/lampposts",
    SystemParameters: "/admin/parameters"
};

export default Links;

export const getLinkDescs = ({ user }) => {
    const userProfile = user && user.profile;
    const latestDesc = {
        name: "Latest Data",
        link: Links.Latest,
        desc:
            "View latest real-time time-series of rolling averages of lamppost pollutant data."
    };
    const getDaDesc = (canOperate) =>
        Object.assign({
            name: "Data Analysis",
            link: Links.DataAnalysis,
            desc:
                "Analysis lamppost pollutant data with time-series and tables, which includes:",
            subLinks: [{ name: "Raw Data", link: Links.DaRaw }].concat(
                canOperate
                    ? [{ name: "Interim Data", link: Links.DaInterim }]
                    : [],
                [{ name: "Approved Data", link: Links.DaApproved }],
                canOperate
                    ? [{ name: "Imported Data", link: Links.DaImported }]
                    : []
            )
        });
    const dvDesc = {
        name: "Data Validation",
        link: Links.Validation,
        desc:
            "View validations of lamppost pollutant data and perform data validation workflow."
    };
    const reportDesc = {
        name: "Report",
        link: Links.Report,
        desc: "View time-series report of lamppost."
    };
    const logDesc = {
        name: "Log",
        link: Links.Logs,
        desc:
            "View audit logs of user actions, such as data validation and system management."
    };

    if (UserUtil.hasSysAdminPrivileges(userProfile)) {
        return [
            latestDesc,
            getDaDesc(true),
            dvDesc,
            reportDesc,
            logDesc,
            {
                name: "System Administration",
                link: Links.Admin,
                desc: "Perform system administrations, which includes:",
                subLinks: [
                    {
                        name: "User Management",
                        link: Links.Users
                    },
                    { name: "Lamppost Management", link: Links.Lampposts },
                    {
                        name: "System Parameter Management",
                        link: Links.SystemParameters
                    }
                ]
            }
        ];
    }
    if (UserUtil.hasDataOperatorPrivileges(userProfile)) {
        return [latestDesc, getDaDesc(true), dvDesc, reportDesc, logDesc];
    }
    if (UserUtil.hasDataBrowserPrivileges(userProfile)) {
        return [latestDesc, getDaDesc(false), reportDesc];
    }
};

export const getLinks = ({ user, link }) => {
    const userProfile = user && user.profile;
    let links = [];
    if (UserUtil.hasDataBrowserPrivileges(userProfile)) {
        links.push({ label: "Home", link: Links.Home });
        links.push({ label: "Latest", link: Links.Latest });
        links.push({ label: "Data Analysis", link: Links.DataAnalysis });
        links.push({ label: "Report", link: Links.Report });
    }
    if (UserUtil.hasDataOperatorPrivileges(userProfile)) {
        links.push({ label: "Data Validation", link: Links.Validation });
        links.push({ label: "Log", link: Links.Logs });
    }
    if (UserUtil.hasSysAdminPrivileges(userProfile)) {
        links.push({ label: "System Administration", link: Links.Admin });
    }
    return links.map((l) => {
        if (!l.name) {
            l.name = l.label;
        }
        l.active = l.link === link;
        return l;
    });
};

export const getAdminLinks = (activeLink) =>
    [
        {
            name: "Users",
            url: Links.Users
        },
        {
            name: "Lampposts",
            url: Links.Lampposts
        },
        {
            name: "System Parameters",
            url: Links.SystemParameters
        }
    ].map((l) => Object.assign({}, l, { active: l.url === activeLink }));

export const removeTrailingSlash = (url) =>
    (url.endsWith("/") ? url.slice(0, -1) : url).replace("/?", "?");

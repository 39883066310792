import { getAuthJson, putAuthJson } from "../utils/fetch";

export const SystemService = (contextUser, apiURL, enableCORS) => {
    const systemParamsURL = `${apiURL}/system/parameters`;
    return {
        getParameters: () =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(systemParamsURL, enableCORS, accessToken)
                ),
        updateParameters: (params) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    putAuthJson(
                        systemParamsURL,
                        params,
                        enableCORS,
                        accessToken
                    )
                )
    };
};

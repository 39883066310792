import {
    getAuthJson,
    postAuthJson,
    putAuth,
    putAuthJson,
    deleteAuth
} from "../utils/fetch";

export const LamppostService = (contextUser, apiURL, enableCORS) => {
    let options = {};
    if (enableCORS) {
        options.mode = "cors";
    }

    const baseURL = `${apiURL}/lampposts`;
    const lamppostIdURL = (lamppostId) => `${baseURL}/${lamppostId}`;
    const lpMonthDatasetsURL = ({ lamppostId, month }) =>
        `${lamppostIdURL(lamppostId)}/metrics/datasets/${month}`;
    const lpValidateDateURL = ({ lamppostId, date }) =>
        `${lamppostIdURL(lamppostId)}/metrics/validate/${date}`;
    const lpTsReportURL = (lamppostId) =>
        `${lamppostIdURL(lamppostId)}/reports/timeseries`;

    return {
        getDistricts: () =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(`${apiURL}/districts`, enableCORS, accessToken)
                ),
        getLampposts: () =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(baseURL, enableCORS, accessToken)
                ),
        saveLamppost: (lamppost) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    postAuthJson(baseURL, lamppost, enableCORS, accessToken)
                ),
        updateLamppost: (lamppost) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    putAuthJson(
                        lamppostIdURL(lamppost.lamppost_id),
                        lamppost,
                        enableCORS,
                        accessToken
                    )
                ),
        deleteLamppost: (lamppostId) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    deleteAuth(
                        lamppostIdURL(lamppostId),
                        enableCORS,
                        accessToken
                    )
                ),
        getLamppostsMovingAvg: (interval, delay) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        baseURL + "/rolling-averages/latest",
                        enableCORS,
                        accessToken,
                        ["interval=" + interval, "delay=" + delay]
                    )
                ),
        getLamppostMovingAvgTimeSeries: (interval, date) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        baseURL + "/rolling-averages/timeseries",
                        enableCORS,
                        accessToken,
                        ["interval=" + interval, "last=" + date]
                    )
                ),
        getMetricDatasets: (queryParams = []) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${baseURL}/metrics/datasets`,
                        enableCORS,
                        accessToken,
                        queryParams
                    )
                ),
        submitMetricDatasetApproval: (lamppostId, month) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    putAuth(
                        `${lpMonthDatasetsURL({ lamppostId, month })}/submit`,
                        enableCORS,
                        accessToken
                    )
                ),
        withdrawMetricDatasetApproval: (lamppostId, month) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    putAuth(
                        `${lpMonthDatasetsURL({ lamppostId, month })}/withdraw`,
                        enableCORS,
                        accessToken
                    )
                ),
        getMetricDataset: (lamppostId, month) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${lpMonthDatasetsURL({ lamppostId, month })}`,
                        enableCORS,
                        accessToken
                    )
                ),
        getValidationsToApprove: (lamppostId, month) =>
            contextUser.getAccessToken().then((accessToken) =>
                getAuthJson(
                    `${lpMonthDatasetsURL({
                        lamppostId,
                        month
                    })}/validations`,
                    enableCORS,
                    accessToken
                )
            ),
        approveMetricDataset: (lamppostId, month) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    postAuthJson(
                        `${lpMonthDatasetsURL({ lamppostId, month })}/approve`,
                        {},
                        enableCORS,
                        accessToken
                    )
                ),
        rejectMetricDataset: (lamppostId, month, justification) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    postAuthJson(
                        `${lpMonthDatasetsURL({ lamppostId, month })}/reject`,
                        { justification },
                        enableCORS,
                        accessToken
                    )
                ),
        getValidateData: (lamppostId, date) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${lpValidateDateURL({ lamppostId, date })}/data`,
                        enableCORS,
                        accessToken
                    )
                ),
        saveInterimData: (lamppostId, date, interims) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    postAuthJson(
                        `${lpValidateDateURL({ lamppostId, date })}`,
                        { changes: interims },
                        enableCORS,
                        accessToken
                    )
                ),
        lockLamppostMetrics: (lamppostId, date) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    putAuth(
                        `${lpValidateDateURL({ lamppostId, date })}/lock`,
                        enableCORS,
                        accessToken
                    )
                ),
        unlockLamppostMetrics: (lamppostId) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    deleteAuth(
                        `${baseURL}/${lamppostId}/metrics/validate/lock`,
                        enableCORS,
                        accessToken
                    )
                ),
        getTimeSeriesReports: (lamppostId) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${lpTsReportURL(lamppostId)}`,
                        enableCORS,
                        accessToken
                    )
                ),
        getTimeSeriesReport: (lamppostId, month, metric) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${lpTsReportURL(lamppostId)}/${month}/${metric}`,
                        enableCORS,
                        accessToken
                    )
                )
    };
};

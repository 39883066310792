import React, { useContext, useState, useEffect, createRef } from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";

import ConfirmBox from "../components/confirmBox";
import Icon, { ChevronDown } from "../components/icon";
import { CurrentUserContext } from "../providers/auth";
import { isEditingValidation, unlockAndAction } from "../utils/prompt-validate";
import { setCurrentLocationAsRedirectUrlWhenNotSet } from "../utils/session";
import Services from "../services";
import { getLinks } from "../utils/links";

import EPDLogo from "../images/EPD.png";
import styles from "./header.module.css";

const Header = ({ siteTitle, link }) => {
    const currentUser = useContext(CurrentUserContext);
    const lamppostService = Services(currentUser).lamppost;

    const [user, setUser] = useState(null);
    const [burger, setBurger] = useState(false);
    const [confirmUnsave, setConfirmUnsave] = useState(false);
    const [confirmUnsaveLogout, setConfirmUnsaveLogout] = useState(false);
    const [navUrl, setNavUrl] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    let userMenu = createRef();
    let sideOpen = burger ? styles.open : styles.closed;

    useEffect(() => {
        if (currentUser) {
            currentUser.getUser().then((user) => {
                setUser(user);
            });
        }
    }, [currentUser]);

    const navToPage = (e, pageUrl) => {
        e.preventDefault();
        if (isEditingValidation()) {
            setNavUrl(pageUrl);
            setConfirmUnsave(true);
        } else {
            navigate(pageUrl);
        }
    };

    const unsaveAndNav = () =>
        unlockAndAction(
            lamppostService,
            () => {
                alert("Cannot discard changes and unlock the lamppost.");
                setConfirmUnsave(false);
            },
            () => navigate(navUrl)
        );

    const onLogout = () => {
        if (!currentUser) {
            return;
        }
        if (isEditingValidation()) {
            setConfirmUnsaveLogout(true);
        } else {
            currentUser.getUser().then((user) => {
                if (user) {
                    currentUser.signOut();
                } else {
                    navigate("/");
                }
            });
        }
    };

    const logout = () => {
        unlockAndAction(
            lamppostService,
            () => {
                alert("Cannot discard changes and unlock the lamppost.");
                setConfirmUnsaveLogout(false);
            },
            () => {
                if (currentUser) {
                    currentUser.signOut();
                }
            }
        );
    };

    return (
        <header className={styles.headerContainer}>
            <div className={`${styles.sidenav} ${sideOpen} mobile`}>
                <div className={styles.navList}>
                    <div>
                        <button
                            className={styles.closebtn}
                            aria-label="Close menu"
                            onClick={() => {
                                setBurger(!burger);
                            }}
                        >
                            &times;
                        </button>
                    </div>
                    {getLinks({ user, link }).map((l, idx) => (
                        <div key={idx} className={styles.navItem}>
                            <button
                                aria-label={l.label}
                                onClick={(e) => navToPage(e, l.link)}
                                className={
                                    "px-6 btn-link " +
                                    (l.active ? styles.active : styles.tab)
                                }
                            >
                                {l.name}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.lt}>
                <div className="flexrow items-center">
                    <button
                        className={`${styles.burger} mobile`}
                        aria-label="Open menu"
                        onClick={() => {
                            setBurger(!burger);
                        }}
                    >
                        &#9776;
                    </button>
                    <img
                        src={EPDLogo}
                        alt={siteTitle}
                        className={`${styles.logo}`}
                    />{" "}
                    <div className={styles.paqs}>PAQS</div>
                </div>
                <div className={`${styles.navBar} desktop`}>
                    {getLinks({ user, link }).map((l, idx) => (
                        <Link
                            key={idx}
                            to={l.link}
                            onClick={(e) => navToPage(e, l.link)}
                            className={`${
                                l.active ? styles.active : styles.tab
                            } btn-link`}
                        >
                            {l.name}
                        </Link>
                    ))}
                </div>
            </div>
            <div className={styles.rt}>
                <div className={styles.controlGroup}>
                    {user ? (
                        <div className={styles.dropdown}>
                            <button
                                className="btn-link flex flex-row items-center"
                                onClick={() => {
                                    setShowDropdown(true);
                                    userMenu.current.focus();
                                }}
                            >
                                <div className={styles.username}>
                                    Hi, {user.profile.sub}
                                </div>
                                <Icon name={ChevronDown} width="16px" />
                            </button>
                            <div
                                className={
                                    styles.dropdownContent +
                                    (showDropdown ? " h-auto" : " h-0")
                                }
                            >
                                <div
                                    ref={userMenu}
                                    tabIndex="0"
                                    onClick={() => setShowDropdown(false)}
                                    onBlur={() => setShowDropdown(false)}
                                >
                                    <button
                                        className="w-full btn-link hover:bg-gray-200"
                                        aria-label="Update password"
                                        onMouseDown={(e) => e.preventDefault()}
                                        onTouchStart={(e) => e.preventDefault()}
                                        onClick={() =>
                                            navigate("/account/password")
                                        }
                                    >
                                        Update password
                                    </button>
                                    <button
                                        className="w-full btn-link hover:bg-gray-200"
                                        aria-label="Logout"
                                        onMouseDown={(e) => e.preventDefault()}
                                        onTouchStart={(e) => e.preventDefault()}
                                        onClick={onLogout}
                                    >
                                        Logout
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <button
                            className="btn-link"
                            aria-label="Login"
                            onClick={() => {
                                if (currentUser) {
                                    setCurrentLocationAsRedirectUrlWhenNotSet();
                                    currentUser.clearSessionAndSignIn();
                                }
                            }}
                        >
                            Login
                        </button>
                    )}
                </div>
            </div>

            {confirmUnsave && (
                <ConfirmBox
                    action={unsaveAndNav}
                    cancelAction={() => {
                        setConfirmUnsave(false);
                    }}
                    labelOk="Discard changes"
                    deleteBtn={true}
                    savingTitle="Please wait"
                >
                    Are you sure to leave the page{" "}
                    <span className="text-red-700">
                        without saving edited records
                    </span>
                    ?
                </ConfirmBox>
            )}

            {confirmUnsaveLogout && (
                <ConfirmBox
                    action={logout}
                    cancelAction={() => {
                        setConfirmUnsaveLogout(false);
                    }}
                    labelOk="Discard changes"
                    deleteBtn={true}
                    savingTitle="Please wait"
                >
                    Are you sure to logout{" "}
                    <span className="text-red-700">
                        without saving edited records
                    </span>
                    ?
                </ConfirmBox>
            )}
        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
    link: PropTypes.string.isRequired
};

Header.defaultProps = {
    siteTitle: ``
};

export default Header;

import {
    getAuthJson,
    getAuthBlob,
    postAuthFormData,
    deleteAuth
} from "../utils/fetch";

export const ImportDataService = (contextUser, apiURL, enableCORS) => {
    const importedURL = `${apiURL}/imported`;
    return {
        getSampleFile: () =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthBlob(
                        `${importedURL}/sample`,
                        enableCORS,
                        accessToken
                    )
                ),
        getImportedDatasets: (page) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${importedURL}/datasets`,
                        enableCORS,
                        accessToken,
                        [].concat(page ? ["page=" + page] : [])
                    )
                ),
        getAllImportedDatasets: (page) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${importedURL}/datasets/all`,
                        enableCORS,
                        accessToken,
                        [].concat(page ? ["page=" + page] : [])
                    )
                ),
        getImportedDatasetTimeRange: (datasetId) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    getAuthJson(
                        `${importedURL}/datasets/${datasetId}/time`,
                        enableCORS,
                        accessToken
                    )
                ),
        importDataset: (datasetName, file) => {
            let formData = new FormData();
            formData.append("dataset_name", datasetName);
            formData.append("file", file);
            return contextUser
                .getAccessToken()
                .then((accessToken) =>
                    postAuthFormData(
                        `${importedURL}/datasets`,
                        formData,
                        enableCORS,
                        accessToken
                    )
                );
        },
        deleteImportedDataset: (datasetId) =>
            contextUser
                .getAccessToken()
                .then((accessToken) =>
                    deleteAuth(
                        `${importedURL}/datasets/${datasetId}`,
                        enableCORS,
                        accessToken
                    )
                )
    };
};

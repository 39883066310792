const POST_LOGIN_REDIRECT_URL = "redirect-after-login";
const LAST_TIMEOUT_LOGOUT_USER = "last-timeout-logout-user";

const setCurrentLocationAsRedirectUrlWhenNotSet = () => {
    const redirectUrl = window.sessionStorage.getItem(POST_LOGIN_REDIRECT_URL);
    if (!redirectUrl) {
        window.sessionStorage.setItem(
            POST_LOGIN_REDIRECT_URL,
            window.location.href
        );
    }
};

const getRedirectUrl = () =>
    window.sessionStorage.getItem(POST_LOGIN_REDIRECT_URL);

const removeRedirectUrl = () =>
    window.sessionStorage.removeItem(POST_LOGIN_REDIRECT_URL);

const setLastTimeoutLogoutUser = (user) =>
    window.sessionStorage.setItem(LAST_TIMEOUT_LOGOUT_USER, user);

const getLastTimeoutLogoutUser = () =>
    window.sessionStorage.getItem(LAST_TIMEOUT_LOGOUT_USER);

const removeLastTimeoutLogoutUser = () =>
    window.sessionStorage.removeItem(LAST_TIMEOUT_LOGOUT_USER);

export {
    setCurrentLocationAsRedirectUrlWhenNotSet,
    getRedirectUrl,
    removeRedirectUrl,
    setLastTimeoutLogoutUser,
    getLastTimeoutLogoutUser,
    removeLastTimeoutLogoutUser
};

import Links from "./links";

const SYS_ADMIN = "SYS_ADMIN";
const DATA_OPERATOR = "DATA_OPERATOR";
const DATA_BROWSER = "DATA_BROWSER";

const isSysAdmin = (u) => u && u.user_type === SYS_ADMIN;

const UserUtil = {
    isSysAdmin,
    isDataOperator: (u) => u && u.user_type === DATA_OPERATOR,
    isDataBrowser: (u) => u && u.user_type === DATA_BROWSER,
    hasSysAdminPrivileges: (u) => isSysAdmin(u),
    hasDataOperatorPrivileges: (u) =>
        u && [SYS_ADMIN, DATA_OPERATOR].indexOf(u.user_type) >= 0,
    hasDataBrowserPrivileges: (u) =>
        u && [SYS_ADMIN, DATA_OPERATOR, DATA_BROWSER].indexOf(u.user_type) >= 0
};

const dataAnalysisLinks = (user) => {
    if (!user) {
        return [];
    }
    const raw = {
        name: "Raw Data Analysis",
        url: Links.DaRaw
    };
    const approved = {
        name: "Approved Data Analysis",
        url: Links.DaApproved
    };
    if (UserUtil.hasDataOperatorPrivileges(user)) {
        return [
            raw,
            {
                name: "Interim Data Analysis",
                url: Links.DaInterim
            },
            approved,
            {
                name: "Imported Data Analysis",
                url: Links.DaImported
            }
        ];
    }
    return [raw, approved];
};

const passwordPatterns = [
    { reg: /[a-z]/, err: "at least 1 lowercase letter" },
    { reg: /[A-Z]/, err: "at least 1 uppercase letter" },
    { reg: /[0-9]/, err: "at least 1 number" },
    { reg: /[^0-9a-zA-Z]/, err: "at least 1 special character" }
];

export default UserUtil;
export const Constants = {
    SYS_ADMIN,
    DATA_OPERATOR,
    DATA_BROWSER
};
export const getDataAnalysisLinks = (user, activeLink) =>
    dataAnalysisLinks(user).map((l) =>
        Object.assign({}, l, { active: activeLink === l.url })
    );

export const checkPassword = (password) => {
    let errs = [];
    if (password.length < 8) {
        errs.push("at least 8 characters");
    }
    errs = errs.concat(
        passwordPatterns
            .filter((pattern) => !pattern.reg.test(password))
            .map((pattern) => pattern.err)
    );
    if (errs.length === 0) {
        return "";
    }
    return "Password must contain " + errs.join(", ") + ".";
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSettings } from "../hooks";
import { UserManager, WebStorageStateStore } from "oidc-client";
import { useIdleTimer } from "react-idle-timer";

export const CurrentUserContext = React.createContext(null);

export const CurrentUserProvider = ({ children }) => {
    const {
        ADMIN_AUTH_URL,
        ADMIN_WEBSITE_URL,
        OAUTH2_CLIENT_ID,
        OAUTH2_AUTH_URL,
        SESSION_REFRESH_INTERVAL_MINUTES
    } = useSettings();

    const [currentUser, setCurrentUser] = useState(null);

    // idle timer for checking if user is idle for more than the time period of each session refresh interval
    const { isIdle, reset } = useIdleTimer({
        timeout: SESSION_REFRESH_INTERVAL_MINUTES * 60 * 1000
    });

    useEffect(() => {
        const userManager = new UserManager({
            authority: OAUTH2_AUTH_URL,
            client_id: OAUTH2_CLIENT_ID,
            redirect_uri: `${ADMIN_WEBSITE_URL}/login/callback`,
            silent_redirect_uri: `${ADMIN_WEBSITE_URL}/login/silent`,
            post_logout_redirect_uri: `${ADMIN_WEBSITE_URL}/logout/callback`,
            response_type: "id_token token",
            scope: "openid",
            revokeAccessTokenOnSignout: false,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        });
        const getUser = () =>
            userManager
                .getUser()
                .then((user) =>
                    user && user.access_token && !user.expired ? user : null
                )
                .catch(() => null);
        setCurrentUser({
            getAccessToken: () =>
                getUser()
                    .then((user) => (user ? user.access_token : null))
                    .catch(() => null),
            refreshUserSession: () =>
                userManager
                    .signinSilent()
                    .then((user) =>
                        user && user.access_token && !user.expired ? user : null
                    )
                    .catch(() => null),
            silentCallback: () => userManager.signinSilentCallback(),
            getUser: () => getUser(),
            getSessionUser: () =>
                userManager
                    .getUser()
                    .then((user) => (user ? user : null))
                    .catch(() => null),
            clearSessionAndSignIn: () => {
                window.location.href = `${ADMIN_AUTH_URL}/clear?redirect_uri=${encodeURIComponent(
                    `${window.location.origin}/login`
                )}`;
            },
            signIn: () => userManager.signinRedirect(),
            signInCallback: () =>
                userManager
                    .signinRedirectCallback()
                    .then(() => userManager.clearStaleState()),
            signOut: () => userManager.signoutRedirect(),
            signOutCallback: () =>
                userManager
                    .signoutRedirectCallback()
                    .then(() => userManager.removeUser())
                    .then(() => userManager.clearStaleState()),
            isIdle: () => isIdle(),
            resetIdleTimer: () => reset()
        });
    }, []);

    return (
        <CurrentUserContext.Provider value={currentUser}>
            {children}
        </CurrentUserContext.Provider>
    );
};

CurrentUserProvider.propTypes = {
    children: PropTypes.node.isRequired
};
